import styled from 'styled-components';
import { motion } from 'framer-motion';
import bgImage from '../../images/background-header-reference.png';

export const CooperationBanerWrapper = styled.header`
  /* background: ${({theme}) => theme.background}; */
  height: 70vh;
  width: 100%;
  position: relative;
  margin-bottom: 9.5rem;
  background-image: url(${bgImage});
  background-size: cover;

  ${({theme}) => theme.media.laptop} {
    height: 100vh;
    margin-bottom: 14.5rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    margin-bottom: 18.5rem;
  }
`


export const BannerTitle = styled(motion.h1)`
  position: absolute;
  bottom: -5rem;
  left: -10px;
  color: ${({theme}) => theme.color};
  pointer-events: none;
  z-index: 5;

  ${({theme}) => theme.media.tabletXL} {
    left: -20px;
    bottom: -4rem;
  }
  ${({theme}) => theme.media.laptopXL} {
    left: -20px;
    bottom: -5rem;
  }
  ${({theme}) => theme.media.desktop} {
    left: -30px;
    bottom: -6rem;
  }
`;


export const Headline = styled(motion.span)`
  display: block;
  font-size: 4.7rem;
  font-weight: 900;
  line-height: 0.86;
  letter-spacing: -0.5rem;
  text-transform: uppercase;

  ${({theme}) => theme.media.tabletXL} {
    font-size: 12rem;
    line-height: 0.76;
    letter-spacing: -1.1rem;
  }
  ${({theme}) => theme.media.laptopXL} {
    font-size: 16rem;
    line-height: 0.76;
    letter-spacing: -1.4rem;
  }
  ${({theme}) => theme.media.desktop} {
    font-size: 18rem;
    letter-spacing: -1.8rem;
  }
`;


// export const ImageWrapper = styled.div`
//   padding-top: 10rem;
//   width: 40%;

//   svg {
//     width: 100%;
//     transform: scale(1.2);
//     z-index: -1;

//     ${({theme}) => theme.media.laptop} {
//       transform: scale(1);
//     }

//     ${({theme}) => theme.media.laptopXL} {
//       width: 96%;
//       display: flex;
//       margin: 0 auto;
//     }
//     ${({theme}) => theme.media.desktop} {
//       width: 85%;
//     }
//   }

//   ${({theme}) => theme.media.laptop} {
//     padding-top: 0;
//   }

//   ${({theme}) => theme.media.desktopXL} {
//     width: 80%;
//     margin: 0 auto;
//     padding-top: .3rem;
//   }
// `