import styled from 'styled-components';


export const ReferenceWrapper = styled.section`
  width: 100%;
  margin-bottom: 4rem;

  ${({theme}) => theme.media.laptopXL} {
    margin-bottom: 8rem;
  }
`


export const BoxImages = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.media.tablet} {
    flex-direction: row;
  }
`;


export const ImgWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  filter: drop-shadow(0px 0px 8px rgba(20,20,20, .4));

  svg {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.laptop} {
    width: 35%;
  }
`;


export const ImgTitle = styled.h3`
  font-size: 1.1rem;
  padding: 1rem 0;
  color: ${({theme}) => theme.color};

  ${({theme}) => theme.media.laptop} {
    font-size: 1.4rem;
  }
`;