import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Container } from '../globalStyles.css'
import { ReferenceWrapper, ImgWrapper, ImgTitle, BoxImages } from './cooperationReference.css'

import discoPdf from '../../assets/pdf/dyskoteka_referencja.pdf';
import koncertPdf from '../../assets/pdf/koncer_referencja.pdf';
import triathonPdf from '../../assets/pdf/triathon.pdf';
import gruzjaPdf from '../../assets/pdf/zycie_gruzji.pdf';
import mediacjePdf from '../../assets/pdf/mediacje.pdf';
import eljazzPdf from '../../assets/pdf/eljazz.pdf';
import barrPdf from '../../assets/pdf/barr.pdf';

const CooperationReference = ({ onCursor }) => {
  const data = useStaticQuery(graphql`
    query {
      discoImage: file(relativePath: { eq: "dyskoteka_referencja.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      concertImage: file(relativePath: { eq: "koncer_referencja.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      triathonImage: file(relativePath: { eq: "triathon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gruzjaImage: file(relativePath: { eq: "zycie-gruzji.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mediacjeImage: file(relativePath: { eq: "mediacje.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eljazzImage: file(relativePath: { eq: "eljazz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      barrImage: file(relativePath: { eq: "barr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ReferenceWrapper>
      
      <Container>
        <BoxImages>
          <ImgWrapper>
            <a 
              href={discoPdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.discoImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Szubiniacy</ImgTitle>
            </a>
          </ImgWrapper>
          <ImgWrapper>
            <a 
              href={koncertPdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.concertImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Armia Celinkowych Aniołów</ImgTitle>
            </a>
          </ImgWrapper>
          <ImgWrapper>
            <a 
              href={triathonPdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.triathonImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Triathlon Żnin</ImgTitle>
            </a>
          </ImgWrapper>
        </BoxImages>

        <BoxImages>
          <ImgWrapper>
            <a 
              href={gruzjaPdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.gruzjaImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Kolacja Życie Gruzji</ImgTitle>
            </a>
          </ImgWrapper>

          <ImgWrapper>
            <a 
              href={mediacjePdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.mediacjeImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Ośrodek Mediacji</ImgTitle>
            </a>
          </ImgWrapper>

          <ImgWrapper>
            <a 
              href={eljazzPdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.eljazzImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Stowarzyszenie Artystyczne Eljazz</ImgTitle>
            </a>
          </ImgWrapper>

        </BoxImages>

        <BoxImages>
          <ImgWrapper>
            <a 
              href={barrPdf} 
              target='_blank' 
              rel="noopener noreferrer"
              onMouseEnter={() => onCursor('hovered')}
              onMouseLeave={onCursor}
            >
              <Img fluid={data.barrImage.childImageSharp.fluid} />
              <ImgTitle><FontAwesomeIcon icon={faArrowRight} /> Bydgoska Agencja Rozwoju Regionalnego</ImgTitle>
            </a>
          </ImgWrapper>

        </BoxImages>

      </Container>
    </ReferenceWrapper>
  )
}

export default CooperationReference
