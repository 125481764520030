import styled from 'styled-components';
import { motion } from 'framer-motion';


export const PowersWrapper = styled.section`
  width: 100%;
  margin-bottom: 8rem;
  z-index: -1;
`;


export const ImgWrapper = styled.div`
  display: none;

  ${({theme}) => theme.media.tablet} {
    display: block;
    width: 32%;
    transform: scale(1.5);
    padding: 2rem 1rem;
    opacity: 0;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 2rem 3rem;
  }
`;


export const TextWrapper = styled.div`
  padding: 2rem 1rem;
  margin-left: 0;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    color: ${({theme}) => theme.color};
  }

  ${({theme}) => theme.media.tablet} {
    padding: 2rem 3rem;
    margin-left: 2rem;
  }
`;


export const AccordionHeader = styled(motion.div)`
  width: 100%;
  color: ${({theme}) => theme.colors.mainRed};
  margin: .5rem 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
`;


export const AccordionIcon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: .5rem;

  span {
    width: 14px;
    height: 4px;
    background: ${({theme}) => theme.colors.mainRed};
    transition: .1s ease-in-out;
  }

  p {
    margin-left: .5rem;
  }
`;


export const AccordionContent = styled(motion.div)`
  overflow: hidden;
  padding-left: 2.3rem;

  span {
    width: 100%;
    margin: .5rem 0;
    font-size: .95rem;
    color: ${({theme}) => theme.colors.mainRed};
    display: block;
    font-weight: 300;
  }
`;