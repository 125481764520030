import React from 'react'
import styled from 'styled-components';

import SEO from "../components/seo"
import Layout from "../components/layout"
import CooperationBanner from '../components/cooperationPage/cooperationBanner'
import CooperationAbout from '../components/cooperationPage/cooperationAbout'
import CooperationReference from '../components/cooperationPage/cooperationReference'
import CooperationPowers from '../components/cooperationPage/cooperationPowers'

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'

import bgImg from '../images/bg-strips.png'
const Wrapper = styled.div`
  width: 100%;
  background-image: url(${bgImg});
  background-position: center;
  background-size: cover;
`;


const CooperationPage = (props) => {

  const { cursorStyles } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
  }

  return (
    <Layout>
      <SEO title="Referencje - BluStreamTV" />

      <CooperationBanner onCursor={onCursor}/>
      <Wrapper>
        <CooperationAbout onCursor={onCursor}/>
        <CooperationReference onCursor={onCursor}/>
      </Wrapper>
      <CooperationPowers onCursor={onCursor} />

    </Layout>
  )
}

export default CooperationPage
