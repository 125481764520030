import React from 'react'

import { CooperationBanerWrapper, BannerTitle, Headline } from './cooperationBanner.css'


const CooperationBanner = () => {

  const parentCooperation = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        staggerChildren: .2,
      },
    },
  }

  const childCooperation = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.9],
      },
    },
  }

  return (
    <CooperationBanerWrapper>
      <BannerTitle variants={parentCooperation} initial='initial' animate='animate'>
        <Headline variants={childCooperation}>Refe-</Headline>
        <Headline variants={childCooperation}>rencje</Headline>
      </BannerTitle>
    </CooperationBanerWrapper>
  )
}

export default CooperationBanner
