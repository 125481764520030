import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

import { Container } from '../globalStyles.css'
import { CooperationAboutSection, Content, ContentTitle, ContentText } from './cooperationAbout.css'


const CooperationAbout = ({ onCursor }) => {
  const animation = useAnimation();
  const [contentRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-15%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <CooperationAboutSection
      ref={contentRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
          opacity: 0,
          y: '50px',
        }
      }}
    >
      <Container>
        <Content>
          <ContentTitle>
            Referencje od Naszych klientów - 
          </ContentTitle>
          <ContentText>
            O sukcesie firmy i jakości oferowanych przez nią usług najlepiej świadczą Klienci. Poniżej prezentujemy wybrane referencje otrzymane od Naszych klientów.
            Jeżeli jesteście Państwo naszymi Klientami i chcielibyście wyrazić opinię o współpracy z nami zachęcamy do <Link 
              to="/kontakt"
              onMouseEnter={() => onCursor('pointer')}
              onMouseLeave={onCursor}
            ><FontAwesomeIcon icon={faArrowRight} /> przesłania opinii</Link>.
          </ContentText>
        </Content>
      </Container>
    </CooperationAboutSection>
  )
}

export default CooperationAbout
