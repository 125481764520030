import React, { useRef, useEffect, Fragment, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion';
import gsap from 'gsap'

import { Container, Flex } from '../globalStyles.css'
import { PowersWrapper, ImgWrapper, TextWrapper, AccordionHeader, AccordionIcon, AccordionContent } from './cooperationPowers.css'
import MobileImage from '../../images/mob-phone.svg'
import { useGlobalStateContext } from '../../context/globalContext'

// TABELA
const accordionIds = [
  {id: 0, title: 'Realizacje', results: ['realizacje obrazu live', 'realizacje dźwięku live', 'realizacje grafik live']},
  {id: 1, title: 'Sprzęt', results: ['6 kamer full HD (w tym 2 mobilne)', '5 kamer efektowych', 'kamery on board', 'zdjęcia z drona']},
  {id: 2, title: 'Ludzie', results: ['2 realizatorów', '6 operatorów', 'grafik', 'inżynier dźwięku', 'dział techniczny na miejscu realizacji']},
  {id: 3, title: 'Oprogramowanie', results: ['zaawansowany system powtórek (slowmotion)', 'indywidualne oprogramowanie transmisji', 'ndywidualne oprogramowanie wyświetlania grafik']},
  {id: 4, title: 'Możliwości podczas live', results: ['studia mobilne + oświetlenie', 'stanowiska komentatorskie', 'stanowisko reporterskie']},
  {id: 5, title: 'Dodatki do transmisji', results: ['montaż skrótów podczas wydarzenia', 'czat na żywo', 'wyświetlanie ścieżki językowej', 'wyświetlenie dodatkowych źródeł']},
]


const CooperationPowers = ({onCursor}) => {
  const [expanded, setExpanded] = useState(0)

  const imgPowersWrapper = useRef(null);
  const [powersRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-10%',
  })

  useEffect(() => {
    if(inView) {
      const div = imgPowersWrapper.current;
      const [elements] = imgPowersWrapper.current.children;

      const phoneMobile = elements.getElementById('mobile-phone')
      const youtube = elements.getElementById('youtube-phone')

      gsap.set(div, {autoAlpha: 0})
      gsap.set(phoneMobile, {transformOrigin: '50%, 50%'})
      gsap.set(youtube, {transformOrigin: '50%, 50%', scale: 0, autoAlpha: 0})

      const tlCooperation = gsap.timeline();

      tlCooperation.to(div, {autoAlpha: 1, opacity: 1}, 0.3)
        .fromTo(phoneMobile, {autoAlpha: 0, y: '+150px'}, {duration: .8, autoAlpha: 1, y: 0, scale: .68}, .4)
        .to(youtube, {duration: 1, autoAlpha: 1, scale: .85})

    }
  }, [inView])


  return (
    <PowersWrapper ref={powersRef}>
      <Container>
        <Flex flexEnd alignTop>
          <ImgWrapper ref={imgPowersWrapper}>
            <MobileImage />
          </ImgWrapper>
          <TextWrapper>
            <h3>Wiele wariantów współpracy</h3>
            {accordionIds.map((details, index) => (
              <Accordion 
                key={index} 
                details={details} 
                expanded={expanded} 
                setExpanded={setExpanded}
                onCursor={onCursor}
              />
            ))}
          </TextWrapper>
        </Flex>
      </Container>
    </PowersWrapper>
  )
}


const Accordion = ({details, expanded, setExpanded, onCursor}) => {
  const isOpen = details.id === expanded
  const [hovered, setHovered] = useState(false)
  const { currentTheme } = useGlobalStateContext()

  return (
    <Fragment>
      <AccordionHeader
        onClick={() => setExpanded(isOpen ? false : details.id)}
        onMouseEnter={() => onCursor('hovered')}
        onMouseLeave={onCursor}
        onHoverStart={() => setHovered(!hovered)}
        onHoverEnd={() => setHovered(!hovered)}
        whileHover={{
          color: currentTheme === 'dark' ? '#FFFFFF' : '#000000'
        }}
      >
        <AccordionIcon>
          <motion.span
            animate={{rotate: isOpen || hovered ? 0 : 45, x: 3}}
            transition={{duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9]}}
          ></motion.span>
          <motion.span
            animate={{rotate: isOpen || hovered ? 0 : -45, x: -3}}
            transition={{duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9]}}
          ></motion.span>
          <p>{details.title}</p>
        </AccordionIcon>
      </AccordionHeader>
      <AccordionContent 
        key='content' 
        animate={{height: isOpen ? '100%' : '0'}}
        transition={{duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9]}}
      >
        {details.results.map((result, index) => (
          <span key={index}>{result}</span>
        ))}
      </AccordionContent>
    </Fragment>
  )
}


export default CooperationPowers
